import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Call, TIMETYPE } from 'src/app/buisness-object/call/Call';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { BaseComponent } from '../BaseComponent';
import { catchError, forkJoin, lastValueFrom, Observable, of, Subscription, tap, throwError } from 'rxjs';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';
import { UserService } from 'src/app/service/user/user.service';
import { User } from 'src/app/buisness-object/user/User';
import { ActivatedRoute } from '@angular/router';
import { PhonelistSidebarComponent } from './phonelist-sidebar/phonelist-sidebar.component';
import { PhonelistPlanningComponent } from './phonelist-planning/phonelist-planning.component';

export enum CallView {
  LIST = 1,
  CREATE = 2,
  PLANNING = 3
}

@Component({
  selector: 'app-phonelist',
  templateUrl: './phonelist.component.html',
  styleUrls: ['./phonelist.component.scss']
})
export class PhonelistComponent extends BaseComponent implements OnInit {

  public selectedCall: Call;
  public customer: Customer;
  public customers: Customer[] = [];
  public users: User[] = [];
  public view = CallView;
  public showView: CallView = CallView.LIST;
  public calls: Call[];
  searchTimeout: any;
  public phoneCallsFiltered: Call[] = [];
  public phoneCallsFilteredTemp: Call[] = [];
  public inputTimeout;
  public callsFiltered: Call[] = [];
  public callsFilteredTemp: Call[] = [];
  public planningActive = false;
  @ViewChild('appPhonelistSidebar') appPhonelistSidebar: PhonelistSidebarComponent;
  @ViewChild('appPhonelistPlanning') appPhonelistPlanning: PhonelistPlanningComponent;
  public timeStampSets;


  constructor(
    private phoneCallService: PhoneCallService,
    private customerService: CustomerService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setUp();
  }

  async setUp() {
    await lastValueFrom(this.requestDataServerside());
  }

  changeViewToEdit(call: Call) {
    this.selectedCall = call;
    this.showView = this.view.CREATE;
  }

  selectionCancelCreate() {
    if(this.planningActive) {
      this.showView = CallView.PLANNING;
    } else {
      this.showView = CallView.LIST;
    }
    this.selectedCall = null;
  }

  selectionCreate() {
    this.showView = this.view.CREATE
  }

  createSuccess(obj: Call) {
    this.updateDataLocal(obj, 'create');
  }

  updateSuccess(obj: Call) {
    this.updateDataLocal(obj, 'update');
  }

  removePhoneCall(obj: Call) {
    this.updateDataLocal(obj, 'delete');
  }

  updateDataLocal(obj: Call, updateTyp: string) {
    if(updateTyp == 'create'){
        this.selectedCall = obj;
        this.selectedCall.customer = obj.customer;
        this.customer = obj.customer;
        this.calls.push(obj);
        if(this.planningActive){
          this.showView = CallView.PLANNING;
        } else {
          this.showView = CallView.LIST;
         //this.appPhonelistSidebar.intiView(this.calls);
        }
    } else if(updateTyp == 'delete') {
      let index = this.calls.findIndex((c) => c.call_id == obj.call_id);
        if(index > -1) {
          this.calls.splice(index,1);
        }
        this.selectedCall = null;
        if(this.appPhonelistSidebar){
          this.appPhonelistSidebar.intiView(this.callsFiltered);
        }
        //this.appPhonelistPlanning.initView(this.callsFiltered);
    } else if(updateTyp == 'update') {
        let index = this.calls.findIndex((o) => o.call_id == obj.call_id);
        if(index > -1){
          this.calls[index] = obj;
        }
        this.selectedCall = obj;
        this.selectedCall.customer = obj.customer;
        if(this.planningActive){
          this.showView = CallView.PLANNING;
        } else {
          this.showView = CallView.LIST;
          //this.appPhonelistSidebar.intiView(this.calls);
        }
    }
    this.selectedCall = null;
    this.timeStampSets = this.generateTimestampSets(this.calls);
  }

  requestDataServerside(): Observable<any> {
    return forkJoin({
      calls: this.phoneCallService.getPhoneCalls(),
      customers: this.customerService.getCustomers(),
      users: this.userService.getUsers()
    }).pipe(
      tap((result) => {
        if(result) {
          this.calls = result.calls;
          this.customers = result.customers;
          this.users = result.users;
          this.calls = this.calls.map(obj => {
            const customer = this.customers.find(c => c.customer_id === obj.customer_id);
            if (customer) {
              obj.customer = customer;
            }
            const user = this.users.find(u => u.userId === obj.user_id);
            if (user) {
              obj.user = user;
            }
            return obj;
          });
          this.callsFiltered = this.calls;
          this.timeStampSets = this.generateTimestampSets(this.calls);
          this.appPhonelistSidebar?.intiView(this.callsFiltered);
        }
      }
    ),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  generateTimestampSets(calls: Call[]) {
    const morningSet = new Set<number>();
    const afternoonSet = new Set<number>();
    for(const call of calls) {
      if (call.time_type == TIMETYPE.MORNING) {
        morningSet.add(call.due_date);
      } else if (call.time_type == TIMETYPE.AFTERNOON) {
        afternoonSet.add(call.due_date);
      }
    }
    return { morningSet, afternoonSet };
  }

  searchCalls(searchValue: string)
  {
    this.callsFiltered = [];
    if(this.calls && searchValue && searchValue.length > 1) {
      this.callsFiltered = this.calls.filter((call) => {
        return call.company_name?.toLowerCase().startsWith(searchValue.toLowerCase()) ||
               call.company_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
               call.note?.toLowerCase().includes(searchValue.toLowerCase()) ||
               call.driver_note?.toLowerCase().includes(searchValue.toLowerCase()) ||
               call.contact_first_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
               call.contact_last_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
               call.created.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
               call.call_id.toString().toLowerCase().includes(searchValue.toLowerCase());
      })
      this.appPhonelistSidebar.intiView(this.callsFiltered);
    } else {
      this.callsFiltered = this.calls;
      this.appPhonelistSidebar.intiView(this.callsFiltered);
    }
  }

  clearSearchBar() {
    let searchCalls = document.getElementById('input-search-calls') as HTMLInputElement;
    if(searchCalls != null) searchCalls.value = '';

    if(this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
    this.callsFiltered = this.calls;
    })
  }

  openPlanningView() {
    this.showView = this.view.PLANNING;
    this.planningActive = true;
  }

  switchViewList() {
    this.showView = this.view.LIST;
    this.planningActive = false;
  }

  switchViewCreate() {
    this.showView = this.view.CREATE;
  }
}
