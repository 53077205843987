<form class="popup-body" [formGroup]="form" *ngIf="showWindow === 'priority'">
  <div class="popup-container">
    <div class="popup-header">
        Priorität ändern
    </div>
    <ul class="popup-priorities">
      <li *ngFor="let type of selectPriorities">
        <button
          type="button"
          [ngClass]="{
            'hoch': selectedPriority === 0 && type.value == selectedPriority,
            'mittel': selectedPriority === 1 && type.value == selectedPriority,
            'niedrig': selectedPriority === 2 && type.value == selectedPriority
          }"
          (click)="onSelectPriority(type.value)">
          <span class="name">{{ type.label }}</span>
        </button>
      </li>
    </ul>
      <button class="popup-footer" (click)="selectionSaveEmitter.emit(selectedCall)">Speichern</button>
  </div>
</form>
