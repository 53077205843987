import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Call } from 'src/app/buisness-object/call/Call';
import { CallForm } from 'src/app/buisness-object/call/form/CallForm';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';

@Component({
  selector: 'app-phonelist-popup',
  templateUrl: './phonelist-popup.component.html',
  styleUrls: ['./phonelist-popup.component.scss']
})
export class PhonelistPopupComponent implements OnInit {

  @Input() selectedCall: Call;
  @Input() showWindow: string;

  @Output() selectionSaveEmitter = new EventEmitter<any>();

  public form: FormGroup;
  public selectPriorities = [
    { label: 'Hoch', value: 0 },
    { label: 'Mittel', value: 1 },
    { label: 'Niedrig', value: 2 }
  ];
  public selectedPriority: number;

  constructor(
    private formbuilder: FormBuilder,
    private phoneCallService: PhoneCallService
  ) { }

  ngOnInit(): void {
    this.form = CallForm.getForm(this.formbuilder, this.selectedCall);
    this.selectedPriority = this.selectPriorities[this.selectedCall.priority].value;
  }

  onSelectPriority(value: number): void {
    this.selectedPriority = value;
    this.selectedCall.priority = value;
    this.updatePhoneCall();
  }

  async updatePhoneCall(){
    await lastValueFrom(this.phoneCallService.updatePhoneCall(this.selectedCall));
  }
}
