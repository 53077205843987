<a (click)="onCallSelected()" class="phone-call-item"
  [class.phone-call-item-active]="active">
  <div class="phone-call-item-body">
    <div class="phone-call-item-body-header">
      <div class="phone-call-item-body-header-column-1">
        <!-- TITLE (#ID Note) HERE -->
        <h2 *ngIf="call?.call_id != null || call.note?.length > 0">#{{call.call_id}} <span>{{call.note}}</span></h2>
        <h2 *ngIf="call?.note == null || call.note?.length == 0" class="phone-call-item-lbl-content"></h2>
        <!-- CONTACT PERSON AND DATE HERE -->
        <label *ngIf="call.contact_first_name != null" >{{call.getContactFullName()}}, {{call.created | date: ' dd.MMM.YYYY, HH:mm'}}</label>
        <label *ngIf="call.contact_first_name == null" >{{call.phone}}, {{call.created | date: 'dd.MMM.YYYY, HH:mm'}}</label>
      </div>
    </div>
    <!-- LABELS HERE -->
    <div class="phone-call-item-body-footer">
      <div class="phone-call-item-body-header-column-2">
        <label 
        *ngIf="call.category"
        class="lbl-tag"
        [ngClass]="{
          'kunde': call.category === 'Kunde',
          'interessent': call.category === 'Interessent',
          'partner': call.category === 'Partner',
          'lieferant': call.category === 'Lieferant',
          'privat': call.category === 'Privat',
          'sonstige': call.category === 'Sonstige'
        }"
        >{{ call.category }}</label>
        <label class="lbl-tag tag-red">Offen</label>
      </div>
    </div>
  </div>
  <img class="phone-call-item-arrow" src="./assets/arrowmenucolor.svg"/>
</a>
