import { ContactPerson } from "../customer/ContactPerson";
import { Customer } from "../customer/Customer";
import { User } from "../user/User";

export enum Priority {
  HIGH = 0,
  MIDDLE = 1,
  LOW = 2
}

export enum TIMETYPE {
  EXACT = 0,
  MORNING = 1,
  AFTERNOON = 2
}

export class Call {
  public customer: Customer;
  public user: User;
  public contact_full_name: string;
  public contact_email: string;
  public time: number;
  public timeAsString: string;
  public gender: string;
  public order_nr: number = 1;
  public contact_position: string;
  constructor(
      public call_id: number,
      public user_id: number,
      public contact_first_name: string,
      public contact_last_name: string,
      public company_name: string,
      public phone: string,
      public category: string,
      public created: number,
      public note: string,
      public driver_note: string,
      public driver_mp3: string,
      public due_date: number,
      public priority: number,
      public status: number,
      public sort_value: number,
      public customer_id: number,
      public contact_person_id: number,
      public time_type: number
  ){
    //this.contact_email = this.getContactEmail();
    this.contact_full_name = this.getFirstAndLastName();
  }

  isExistingContact(): boolean {
    return this.customer_id != null && this.contact_person_id != null
  }

  getContactEmail(): string {
    let contact = this.customer?.contact_persons?.find(c => c.contact_id == this.contact_person_id);
    return contact?.email;
  }

  getFirstAndLastName(): string {
    if(
       this.contact_first_name &&
      this.contact_first_name.length > 0 &&
      this.contact_last_name &&
      this.contact_last_name.length > 0){
     return `${this.contact_first_name} ${this.contact_last_name}`;
    }
    return '';
  }

  getFirstAndLastNameCustomerContact(): string {
    if(this.customer_id &&
       this.contact_person_id &&
       this.contact_first_name &&
      this.contact_first_name.length > 0 &&
      this.contact_last_name &&
      this.contact_last_name.length > 0){
     return `${this.contact_first_name} ${this.contact_last_name}`;
    }
    return '';
  }

  getContactFullName(): string {
    let contact_first_name = this.contact_first_name ? this.contact_first_name : '';
    let contact_last_name = this.contact_last_name ? this.contact_last_name : '';
    return `${contact_first_name} ${contact_last_name}`;
  }


  getJSON(): any {
    return {
        "call_id": this.call_id,
        "user_id": this.user_id,
        "contact_first_name": this.contact_first_name,
        "contact_last_name": this.contact_last_name,
        "company_name": this.company_name,
        "phone": this.phone,
        "category": this.category,
        "created": this.created,
        "note": this.note,
        "driver_note": this.driver_note,
        "driver_mp3": this.driver_mp3,
        "due_date": this.due_date,
        "priority": this.priority,
        "status": this.status,
        "sort_value": this.sort_value,
        "customer_id": this.customer_id,
        "contact_person_id": this.contact_person_id,
        "time_type": this.time_type,
      }
    }
}
