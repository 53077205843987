import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, forkJoin, lastValueFrom, Observable, Subscription, tap, throwError } from 'rxjs';
import { Call } from 'src/app/buisness-object/call/Call';
import { CallForm } from 'src/app/buisness-object/call/form/CallForm';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { User } from 'src/app/buisness-object/user/User';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';

@Component({
  selector: 'app-phonelist-details',
  templateUrl: './phonelist-details.component.html',
  styleUrls: ['./phonelist-details.component.scss']
})
export class PhonelistDetailsComponent implements OnInit, OnDestroy {

  @Input() calls: Call[] = [];
  @Input() users: User[] = [];
  @Output() removeCallEmitter = new EventEmitter<any>();
  public customers: Customer[] = [];
  public routeSubscription: Subscription;
  public selectedCall: Call;
  public infoActive = false;
  public selectPriorities = [
    { label: 'Hoch', value: 0 },
    { label: 'Mittel', value: 1 },
    { label: 'Niedrig', value: 2 }
  ];
  public timeAsString: string;
  constructor(
    private customerService: CustomerService,
    private activateRoute: ActivatedRoute,
    private phoneCallService: PhoneCallService
  ) {}

  ngOnInit(): void {
    this.setUp();
  }

  ngOnDestroy(): void {
    if(this.routeSubscription){
      this.routeSubscription.unsubscribe();
    }
  }

  async setUp() {
    await lastValueFrom(this.requestDataServerside());
    this.setRouteSubscriptions();
  }

  setRouteSubscriptions() {
    this.routeSubscription = this.activateRoute.queryParams.subscribe((params) => {
      if(params && Number(params['call_id'])){
        const foundCall = this.calls?.find(c => c.call_id == Number(params['call_id']));
        if(foundCall){
          this.selectedCall = foundCall;
        }
      } else {
        this.selectedCall = this.calls.length > 0 ? this.calls[0] : null;
      }
      this.initView();
    })
  }

  requestDataServerside(): Observable<any> {
    return forkJoin({
      customers: this.customerService.getCustomers(),
    }).pipe(
      tap((result) => {
        if(result) {
          this.customers = result.customers;
        }
      }),
      catchError((error) => {
        console.error(error);
        return null;
      })
    );
  }

  initView() {
    if(this.selectedCall == null){
      return;
    }
    let time = new Date(this.selectedCall.due_date)
    if(time.getHours() >= 12) {
      this.timeAsString = 'Nachmittag'
    } else {
      this.timeAsString = 'Vormittag'
    }
  }

  removeCallFromList(call: Call) {
    this.phoneCallService.deletePhoneCall(call.call_id).subscribe((success) => {
      if(success) {
        this.removeCallEmitter.emit(call);
        this.selectedCall = null;
      }
    })
  }

}
